import React, { useState } from "react";
import { GridRadioDiv, Input, SubTitle, SubmitButton, TextArea, Title } from "./styles";
import RadioButton from "src/componentsV2/ui/RadioButton";
import Toggle from "./Toggle";
import backArrow from "src/images/back_arrow.svg";

const workerPrefs = [
    "Team members",
    "Employees",
    "Colleagues",
    "Team mates",
    "Associates",
    "Other (please specify)",
];

const reportRefs = [
    "Direct report",
    "Manager",
    "Supervisor",
    "Boss",
    "Team Leader/Leader",
    "Director",
    "Department head",
    "Other (please specify)",
];

const executiveTeamRefs = [
    "Executive team",
    "Leadership team",
    "Senior management",
    "Other (please specify)",
];

const hrDeptRefs = [
    "Human Resources (HR)",
    "HR Department",
    "HR Team",
    "Other (please specify)",
];

interface FormState {
    otherCompanyRef: boolean
    otherReportRef: boolean
    otherExecutiveTeamRef: boolean
    otherHRDeptRef: boolean
    disklikesOrPrefs: boolean
    commonTerminologyPhrases: boolean
    particularPhrasesToAvoid: boolean
    workerRefIndex: number
    companyRefIndex: number
    reportRefIndex: number
    executiveTeamRefIndex: number
    hrDeptRefIndex: number
}

interface FormProps {
    onNext: Function
    visible: boolean
}

const Form1: React.FC<FormProps> = props => {
    const [state, setState] = useState<FormState>({
        otherCompanyRef: false,
        otherReportRef: false,
        otherExecutiveTeamRef: false,
        otherHRDeptRef: false,
        disklikesOrPrefs: false,
        commonTerminologyPhrases: false,
        particularPhrasesToAvoid: false,
        workerRefIndex: 0,
        companyRefIndex: 0,
        reportRefIndex: 0,
        executiveTeamRefIndex: 0,
        hrDeptRefIndex: 0,
    });

    return (
        <div className={props.visible ? "visible" : "hidden"}>
            {/* Your details */}
            <Title>Your details</Title>
            <SubTitle>Who are you?</SubTitle>
            <div className="flex">
                <Input
                    name="fullName"
                    placeholder="Full name"
                    type="text" />

                <Input
                    name="emailAddress"
                    placeholder="Email address"
                    type="email" />
            </div>
            <SubTitle>Your company name</SubTitle>
            <Input
                name="companyName"
                placeholder="Your company name"
                type="text" />

            {/* Terminology preferences */}
            <Title marginTop={40}>Terminology preferences</Title>
            <SubTitle>How should we refer to someone who works at your company?</SubTitle>
            <GridRadioDiv>
                {workerPrefs.map((ref, index) => (
                    <RadioButton
                        key={index}
                        name="workersPref"
                        caption={ref}
                        value={ref}
                        captionClassName={index === state.workerRefIndex && `opacity-100 font-bold`}
                        checked={index === state.workerRefIndex}
                        onChange={() => setState({ ...state, workerRefIndex: index })}
                    />
                ))}
            </GridRadioDiv>
            {state.workerRefIndex === workerPrefs.length - 1 &&
                <Input
                    name="workersPrefOther"
                    placeholder="Enter your preference"
                    type="text" />
            }

            <SubTitle marginTop={45}>How should we refer to your company?</SubTitle>
            <TextArea
                name="companyRef"
                placeholder="Example: full company name [AirMason Inc.], abbreviated version [AM], nickname for team members [‘Masons], etc)"
                className="h-20 mt-4" />

            <SubTitle marginTop={48}>How should we refer to a <b>Direct Report/Manager</b>?</SubTitle>
            <GridRadioDiv>
                {reportRefs.map((ref, index) => (
                    <RadioButton
                        key={index}
                        name="reportRef"
                        caption={ref}
                        value={ref}
                        captionClassName={index === state.reportRefIndex && `opacity-100 font-bold`}
                        checked={index === state.reportRefIndex}
                        onChange={() => setState({ ...state, reportRefIndex: index })}
                    />
                ))}
            </GridRadioDiv>
            {state.reportRefIndex === reportRefs.length - 1 &&
                <Input
                    name="reportRefOther"
                    placeholder="Enter your preference"
                    type="text" />
            }

            <SubTitle marginTop={48}>How should we refer to the <b>Executive Team?</b></SubTitle>
            <GridRadioDiv>
                {executiveTeamRefs.map((ref, index) => (
                    <RadioButton
                        key={index}
                        name="executiveTeamRef"
                        caption={ref}
                        value={ref}
                        captionClassName={index === state.executiveTeamRefIndex && `opacity-100 font-bold`}
                        checked={index === state.executiveTeamRefIndex}
                        onChange={() => setState({ ...state, executiveTeamRefIndex: index })}
                    />
                ))}
            </GridRadioDiv>
            {state.executiveTeamRefIndex === executiveTeamRefs.length - 1 &&
                <Input
                    name="executiveTeamRefOther"
                    placeholder="Enter your preference"
                    type="text" />
            }

            <SubTitle marginTop={48}>How should we refer to <b>Human Resources?</b></SubTitle>
            <GridRadioDiv>
                {hrDeptRefs.map((ref, index) => (
                    <RadioButton
                        key={index}
                        name="hrDeptRef"
                        caption={ref}
                        value={ref}
                        captionClassName={index === state.hrDeptRefIndex && `opacity-100 font-bold`}
                        checked={index === state.hrDeptRefIndex}
                        onChange={() => setState({ ...state, hrDeptRefIndex: index })}
                    />
                ))}
            </GridRadioDiv>
            {state.hrDeptRefIndex === hrDeptRefs.length - 1 &&
                <Input
                    name="hrDeptRefOther"
                    placeholder="Enter your preference"
                    type="text" />
            }

            <div className="flex mt-10 justify-between">
                <SubTitle marginTop={1}>Any or dislikes or preferences we should consider?</SubTitle>
                <Toggle
                    label={{ on: "Yes", off: "No" }}
                    defaultChecked={false}
                    onChange={(checked: boolean) => setState({ ...state, disklikesOrPrefs: checked })}
                />
            </div>
            <span className="mt-6 italic text-sm">E.g no complex vocabulary, overly conversational tone, slang, idiomatic expressions, emojis, etc.</span>
            {state.disklikesOrPrefs &&
                <TextArea
                    name="disklikesOrPrefs"
                    placeholder="Please specify..."
                    className="h-25 mt-4" />
            }

            <div className="flex mt-10 justify-between">
                <SubTitle marginTop={1}>Are there any common company terminology and phrases (frequently used in workplace conversations and communications) that we should incorporate?</SubTitle>
                <Toggle
                    label={{ on: "Yes", off: "No" }}
                    defaultChecked={false}
                    onChange={(checked: boolean) => setState({ ...state, commonTerminologyPhrases: checked })}
                />
            </div>
            {state.commonTerminologyPhrases &&
                <TextArea
                    name="commonTerminologyPhrases"
                    placeholder="Please specify..."
                    className="h-25 mt-4" />
            }

            <div className="flex mt-10 justify-between">
                <SubTitle marginTop={1}>Are there any words or phrases that should be avoided (e.g., using “family” instead of “team”)?</SubTitle>
                <Toggle
                    label={{ on: "Yes", off: "No" }}
                    defaultChecked={false}
                    onChange={(checked: boolean) => setState({ ...state, particularPhrasesToAvoid: checked })} />
            </div>
            {state.particularPhrasesToAvoid &&
                <TextArea
                    name="particularPhrasesToAvoid"
                    placeholder="Please specify..."
                    className="h-25 mt-4" />
            }

            <div className="flex justify-between mt-12">
                <div className="flex items-center cursor-pointer disabled opacity-50">
                    <img src={backArrow} alt="back" />
                    <p className="text-black-1 text-xl ml-4">Back</p>
                </div>
                <p className="text-black-1 text-xl ml-4 mt-1.5">1 of 3</p>
                <SubmitButton
                    type="submit"
                    onClick={() => props.onNext()}>
                    Continue
                </SubmitButton>
            </div>
        </div>
    )
}

export default Form1;